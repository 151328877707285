import React, { useEffect, useState } from "react";
import {
  Grid,
  Button,
  Typography,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { useHttpRequest } from "../../hooks/useHttpRequest";
import { useStoreState } from "../../context/StoreContext";
import OrderPageHeader from "./components/OrderPage/OrderPageHeader";
import OrderItem from "./components/OrderPage/OrderItem";
import Swal from "sweetalert2";
import { useUserState } from "../../context/UserContext";
import { useRoleAuthorization } from "../../hooks/useRoleAuthorization";
import { PrivilegeModules, PrivilegeActions } from "../../data/privileges.enum";
import OrderWithdrawalView from "./components/OrderPage/OrderWithdrawalView";

export default function NewOrder(props) {
  useEffect(() => {
    document.title = "New - Material Request Card";

    // redirect to default page if not authorized
    if (
      auth.isPrivilegeDataLoaded() &&
      !auth.checkModulePrivilege(
        PrivilegeModules.material_request,
        PrivilegeActions.add,
      )
    ) {
      props.history.push("/app/dashboard");
    }
  }, []);

  const [selectedItems, setSelectedItems] = useState({});
  const [selectedItemsArr, setSelectedItemsArr] = useState([]);
  const [successMessage, setSuccessMessage] = useState(null);
  const { store } = useStoreState();
  const [withdrawlOptionList, setWithdrawlOptionList] = useState([]);

  const backToOrderList = () => {
    window.location.href = "#/app/material-request/list";
  };

  const { userInfo } = useUserState();
  const { auth } = useRoleAuthorization();
  const {
    isLoading,
    httpRequestError: error,
    responseData,
    sendRequest: formSubmitCaller,
  } = useHttpRequest();
  const formValidation = useForm({ mode: "all" });
  const {
    register,
    handleSubmit,
    watch,
    trigger,
    formState: { errors: formErrors },
    clearErrors,
    setValue,
    getValues,
    control,
  } = formValidation;

  useEffect(() => {
    if (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: responseData?.message,
      });
    }
  }, [error, responseData]);

  useEffect(() => {
    if (successMessage) {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: successMessage,
        willClose: backToOrderList,
      });
    }
  }, [successMessage]);

  useEffect(() => {
    loadWithdrawalOption();
  }, []);

  const handleSubmitForm = async (data) => {
    const orderData = {
      storeCode: store.code,
      items: selectedItemsArr.map((item) => {
        return { itemId: item._id, itemQty: item.requestedQty };
      }),
      teamNo: userInfo.username,
      pkrNo: data.pkrNo,
      remark: data.remark.trim().toUpperCase(),
      isWithdrawal: data.isWithdrawal,
      staffId: data?.staff?.username,
      staffName: data?.staff?.name,
      staffDepartment: data?.staff?.department,
      staffCustomerNo: data?.staff?.customerNo,
      companyCode: data?.company?.code,
    };
    // when project request
    if (data.projectManager) {
      orderData.projectManager = {
        userId: data.projectManager.userId,
        userName: data.projectManager.userName,
        projectGroup: data.projectManager.projectGroup,
      };
      if (data.project) {
        orderData.project = {
          enabled: data.project.enabled,
          projectGroup: data.project.projectGroup,
          projectId: data.project.projectId,
          projectName: data.project.projectName,
          company: data.project.company,
        };
      }
    }

    await formSubmitCaller("/v1/material-order", "POST", orderData).then(
      (result) => {
        if (!result?.data?.error) {
          const message = data.projectManager
            ? `New material request ${result.data.order.incrementId} has been created and require approval from project manager ${result.data.order.projectManager.userId}-${result.data.order.projectManager.userName}.`
            : auth.checkModulePrivilege(
                PrivilegeModules.material_request,
                PrivilegeActions.need_approval,
                false,
              )
            ? `New material request ${result.data.order.incrementId} has been created and require approval from warehouse manager.`
            : `New material request ${result.data.order.incrementId} has been created.`;
          setSuccessMessage(message);
        }
      },
    );
  };

  function loadWithdrawalOption() {
    formSubmitCaller(
      `/v1/user/get-list-by-role/option_withdrawal`,
      "GET",
      {},
    ).then((response) => {
      const list = response.data?.users || [];
      setWithdrawlOptionList(list.filter((i) => i.status));
    });
  }

  return (
    <>
      {isLoading && (
        <Backdrop style={{ zIndex: 1 }} open={isLoading}>
          <CircularProgress color={"inherit"} />
        </Backdrop>
      )}
      <FormProvider {...formValidation}>
        <form onSubmit={handleSubmit(handleSubmitForm)}>
          <Grid container spacing={{ xs: 2, md: 3 }}>
            <Grid item xs={12} sm={12} md={8} lg={8}>
              <Typography variant={"h1"}>
                Material Request Card - New
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} className={"action"}>
              <Button onClick={() => props.history.replace("list")}>
                Cancel
              </Button>
              <Button className={"primary"} type={"submit"}>
                Save
              </Button>
            </Grid>
            <OrderPageHeader
              formErrors={formErrors}
              register={register}
              watch={watch}
              getValues={getValues}
              setValue={setValue}
              clearErrors={clearErrors}
              control={control}
              trigger={trigger}
            />
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <OrderWithdrawalView
                formValidation={formValidation}
                withdrawlOptionList={withdrawlOptionList}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <OrderItem
                selectedItems={selectedItems}
                setSelectedItems={setSelectedItems}
                selectedItemsArr={selectedItemsArr}
                setSelectedItemsArr={setSelectedItemsArr}
              />
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </>
  );
}
