import { withRouter } from "react-router-dom";
import { PrivilegeActions, PrivilegeModules } from "../../data/privileges.enum";
import { useRoleAuthorization } from "../../hooks/useRoleAuthorization";
import {
  Autocomplete,
  Backdrop,
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Popover,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  ArrowBack,
  KeyboardArrowDown,
  PlaylistPlay,
  Search as SearchIcon,
  MoreVertOutlined as ExpandMoreIcon,
} from "@mui/icons-material";
import DataTable from "react-data-table-component";
import { useEffect, useState } from "react";
import { useHttpRequest } from "../../hooks/useHttpRequest";
import {
  setRighbarContent,
  toggleRightbar,
  useLayoutDispatch,
  useLayoutState,
} from "../../context/LayoutContext";
import useStyles from "./styles";
import carWashRequestTableColumnConfig from "./data-table/car-wash-request-table-column-config";
import MatrixFilterMobile from "./components/CarWashRequestFilterMobile";
import Swal from "sweetalert2";
import DateFilter from "../../components/DateFilter";
import { getFromDate, getToDate } from "../../helper/list-filters-helper";
import { useUserState } from "../../context/UserContext";
import moment from "moment";
import downloadCSV from "../../helper/download-csv";
import * as _ from "lodash";

// **************************************************************

const CarWashRequestList = (props) => {
  const { auth } = useRoleAuthorization();
  const {
    isLoading,
    httpRequestError: error,
    responseData,
    sendRequest,
  } = useHttpRequest();
  const { userInfo } = useUserState();
  const theme = useTheme();
  const matchMediaQuery = useMediaQuery(theme.breakpoints.down("md"));
  const [matrixList, setMatrixList] = useState([]);
  const [textFilter, setTextFilter] = useState("");
  const layoutDispatch = useLayoutDispatch();
  const classes = useStyles();
  const layoutState = useLayoutState();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [collectionSize, setCollectionSize] = useState(10);
  const [sortBy, setSortBy] = useState({
    column: undefined,
    direction: undefined,
  });
  const [refresh, setRefresh] = useState(false); // refresh list
  const [popoverButtonOpen, setPopoverButtonOpen] = useState(null);
  const [lastDayFromRequestDate, setLastDayFromRequestDate] = useState(
    new Date(),
  );
  const [lastDayToRequestDate, setLastDayToRequestDate] = useState(null);
  const [washedDate, setWashedDate] = useState(null);
  const [requestedFromDate, setRequestedFromDate] = useState(null);
  const [requestedToDate, setRequestedToDate] = useState(null);
  const [checkedDate, setCheckedDate] = useState(null);

  const [optionLeaderList, setOptionLeaderList] = useState([]);
  const [optionLeaderFilter, setOptionLeaderFilter] = useState(null);

  const defaultStatusFilter = userInfo?.roles?.includes("admin")
    ? "NO ACTIONS"
    : auth.checkModulePrivilege(
        PrivilegeModules.car_wash_request,
        PrivilegeActions.option_supervisor,
      )
    ? "SUBMITED"
    : "PENDING";

  const [statusFilter, setStatusFilter] = useState(defaultStatusFilter);
  const statusList = [
    "PENDING",
    "SUBMITED",
    "APPROVED",
    "REJECTED",
    "NO ACTIONS",
  ];

  // Matrix table column
  const columns = carWashRequestTableColumnConfig({
    allowedViewDetail: auth.checkModulePrivilege(
      PrivilegeModules.car_wash_request,
      PrivilegeActions.view_detail,
    ),
  });

  const mobileFilterProps = {
    statusFilter,
    setStatusFilter,
    statusList,
    optionLeaderList,
    optionLeaderFilter,
    setOptionLeaderFilter,
    lastDayFromRequestDate,
    lastDayToRequestDate,
    setLastDayFromRequestDate,
    lastDayToRequestDate,
    lastDayFromRequestDate,
    setLastDayToRequestDate,
    requestedFromDate,
    setRequestedFromDate,
    requestedToDate,
    setRequestedToDate,
    washedDate,
    setWashedDate,
    checkedDate,
    setCheckedDate,
  };

  const filters = {
    text: textFilter.trim(),
    status: statusFilter,
    team: optionLeaderFilter ? optionLeaderFilter.username : undefined,
    lastAtFrom: lastDayFromRequestDate
      ? getFromDate(lastDayFromRequestDate)
      : undefined,
    lastAtTo: lastDayToRequestDate
      ? getToDate(lastDayToRequestDate)
      : undefined,
    requestedFromAt: requestedFromDate
      ? getFromDate(requestedFromDate)
      : undefined,
    requestedToAt: requestedToDate ? getFromDate(requestedToDate) : undefined,
    checkedAt: checkedDate ? getFromDate(checkedDate) : undefined,
    washedAt: washedDate ? getFromDate(washedDate) : undefined,
  };

  // ************************** FUNCTION **********************

  function filter() {
    sendRequest(
      `/v1/car-wash-request/filter?filters=${JSON.stringify(filters)}
      &page=${page}&pageSize=${pageSize}
      ${
        sortBy?.column && sortBy?.direction
          ? "&orderBy=" + sortBy?.column + "&direction=" + sortBy?.direction
          : ""
      }`,
      "GET",
      {},
    ).then((response) => {
      const matrixs = response.data?.matrixs || [];
      setMatrixList(matrixs);
      setCollectionSize(response.data?.total);
    });
  }

  function handleKeyDown(e) {
    if (e.key === "Enter") {
      // perform search when Enter
      e.preventDefault();
      setRefresh(true);
    }
  }

  function sortByColumn(columnConfig, sortDirection) {
    if (columnConfig.sortField && sortDirection) {
      setSortBy({
        column: columnConfig.sortField,
        direction: sortDirection,
      });
      setRefresh(true);
    }
  }

  function handleRowsPerPageChanged(count) {
    setPageSize(count);
    setRefresh(true);
  }

  function handlePageChanged(page) {
    setPage(page);
    setRefresh(true);
  }

  async function initialData() {
    const response = await sendRequest(
      `/v1/user/get-list-by-role/option_leader`,
      "GET",
      {},
    );

    const list = response.data?.users || [];
    const listFilter = list.filter((i) => i.status);
    setOptionLeaderList(listFilter);

    return listFilter;
  }

  async function handleDownloadReport() {
    try {
      let res;

      res = await sendRequest(`/v1/car-wash-request/resend-report`, "POST", {});

      Swal.fire({
        icon: "success",
        title: "Success",
        text: res?.data?.message,
      });
    } catch (err) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: err,
      });
    }
  }

  function handleClosePopoverButton() {
    setPopoverButtonOpen(null);
  }

  function handleOpenPopoverButton(event) {
    setPopoverButtonOpen(event.currentTarget);
  }

  async function downloadCarWashRequestReport() {
    handleClosePopoverButton();
    const csvData = [];

    try {
      const { data } = await sendRequest(
        `/v1/car-wash-request/filter?filters=${JSON.stringify(filters)}
      &page=${page}&pageSize=0
      ${
        sortBy?.column && sortBy?.direction
          ? "&orderBy=" + sortBy?.column + "&direction=" + sortBy?.direction
          : ""
      }`,
        "GET",
        {},
      );

      // Transform booking data for CSV
      for (const request of data.matrixs) {
        const transformedData = {
          "Request No.": request.carWashRequestNo,
          "Request Date": moment(request.requestedAt).format("DD/MM/YYYY"),
          "Last Day": request.lastAt
            ? moment(request.lastAt).format("DD/MM/YYYY")
            : "-",
          "Car Plate No.": request.carNo ?? "-",
          Team: request.team,
          Status: request.status,
          "Washed By": request.washedBy ?? "-",
          "Washed Date": request.washedAt
            ? moment(request.washedAt).format("DD/MM/YYYY")
            : "-",
          "Checked By": request.checkedBy ?? "-",
          "Checked Date": request.checkedAt
            ? moment(request.checkedAt).format("DD/MM/YYYY")
            : "-",
        };

        csvData.push(transformedData);
      }

      // Generate filename and download CSV
      const today = moment().format("YYYYMMDD_HHmmss");
      const filename = `CAR-WASH-REPORT-DETAILS-${today}.csv`;
      downloadCSV(csvData, filename);
    } catch (err) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: err,
      });
    }
  }

  async function downloadWeeklyStatusByTeam() {
    handleClosePopoverButton();
    const csvData = [];

    try {
      const { data } = await sendRequest(
        `/v1/car-wash-request/filter?filters=${JSON.stringify(filters)}
      &page=${page}&pageSize=0
      ${
        sortBy?.column && sortBy?.direction
          ? "&orderBy=" + sortBy?.column + "&direction=" + sortBy?.direction
          : ""
      }`,
        "GET",
        {},
      );

      const uniqueDates = _.uniq(
        data.matrixs.map((item) =>
          moment(item.requestedAt).format("DD/MM/YYYY"),
        ),
      ).sort();

      uniqueDates.sort((a, b) => {
        const momentA = moment(a, "DD/MM/YYYY");
        const momentB = moment(b, "DD/MM/YYYY");
        return momentA.valueOf() - momentB.valueOf();
      });

      const weeklyStatusByTeamData = generateWeeklyStatusByTeamData(
        data.matrixs,
      );

      const teams = Object.keys(weeklyStatusByTeamData);

      const header = [
        "Team",
        ...uniqueDates.map(
          (item) => `${item} (Week ${moment(item, "DD/MM/YYYY").week()})`,
        ),
      ];

      csvData.push(header);

      for (const team of teams) {
        const row = [];
        row.push(team);
        uniqueDates.forEach((date) => {
          row.push(weeklyStatusByTeamData[team][date]);
        });
        csvData.push(row);
      }

      const today = moment().format("YYYYMMDD_HHmmss");
      const filename = `CAR-WASH-WEEKLY-STATUS-BY-TEAM-REPORT-${today}.csv`;
      downloadCSV(csvData, filename, [], { isColumnnDef: false });
    } catch (err) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: err,
      });
    }
  }

  function generateWeeklyStatusByTeamData(data) {
    const uniqueDates = _.uniq(
      data.map((item) => moment(item.requestedAt).format("DD/MM/YYYY")),
    ).sort();
    const uniqueTeams = _.uniq(data.map((item) => item.team)).sort();

    const result = {};

    uniqueTeams.forEach((team) => {
      result[team] = {};
      uniqueDates.forEach((date) => {
        result[team][date] = "-";
      });
    });

    data.forEach((item) => {
      let label = "";
      switch (item.status) {
        case "PENDING":
          label = "PENDING";
          break;
        case "NO ACTIONS":
          label = "NO ACTION";
          break;
        case "SUBMITED":
          label = `SUBMITTED ${moment(item.washedAt).format("DD/MM/YYYY")}`;
          break;
        case "APPROVED":
          label = `APPROVED (${item.checkedBy}) ${moment(item.checkedAt).format(
            "DD/MM/YYYY",
          )}`;
          break;
        case "REJECTED":
          label = "REJECTED";
          break;
        default:
          label = "-";
          break;
      }
      result[item.team][moment(item.requestedAt).format("DD/MM/YYYY")] = label;
    });

    return result;
  }

  // ************************** HOOK **********************

  useEffect(() => {
    setRefresh(true);
  }, [
    statusFilter,
    optionLeaderFilter,
    lastDayFromRequestDate,
    lastDayToRequestDate,
    washedDate,
    requestedFromDate,
    requestedToDate,
    checkedDate,
  ]);

  useEffect(() => {
    if (refresh) {
      filter();
      setRefresh(false);
    }
  }, [refresh]);

  useEffect(() => {
    if (matchMediaQuery) {
      // set filter right bar
      setRighbarContent(
        layoutDispatch,
        [
          <Typography variant={"h2"} key={"rb-header"}>
            Filter
          </Typography>, // header text
          <MatrixFilterMobile
            key={"rb-body"}
            handleKeyDown={handleKeyDown}
            {...mobileFilterProps}
          />,
        ],
        { size: "xs", backButton: true },
      ); // filter content
    }
  }, [
    matchMediaQuery,
    statusFilter,
    optionLeaderFilter,
    lastDayFromRequestDate,
    lastDayToRequestDate,
    washedDate,
    requestedFromDate,
    requestedToDate,
    checkedDate,
  ]);

  useEffect(() => {
    document.title = "Car Wash Request List";
    // redirect to default page if not authorized
    if (
      auth.isPrivilegeDataLoaded() &&
      !auth.checkModulePrivilege(
        PrivilegeModules.car_wash_request,
        PrivilegeActions.view_list,
      )
    ) {
      props.history.push("/app/dashboard");
    }

    initialData();
  }, []);

  useEffect(() => {
    if (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: responseData?.message,
      });
    }
  }, [error, responseData]);

  // ************************** RENDER **********************

  const titleRender = (
    <Typography variant={"h1"}>Car Wash Request List</Typography>
  );

  const actionRender = (
    <>
      {auth.checkModulePrivilege(
        PrivilegeModules.car_wash_request,
        PrivilegeActions.add,
      ) && (
        <Button
          className={"primary"}
          onClick={() => props.history.push("card")}
        >
          New
        </Button>
      )}
      {auth.checkModulePrivilege(
        PrivilegeModules.car_wash_request,
        PrivilegeActions.download_report,
      ) && (
        <Button className={"primary"} onClick={handleOpenPopoverButton}>
          Download Report <ExpandMoreIcon />
        </Button>
      )}
      {auth.checkModulePrivilege(
        PrivilegeModules.car_wash_request,
        PrivilegeActions.resend_report,
      ) && (
        <Button className={"primary"} onClick={handleDownloadReport}>
          Resend report
        </Button>
      )}
    </>
  );

  const filterRender = (
    <>
      <Grid item xs={10} sm={10} md={2} lg={2}>
        <TextField
          autoComplete={"off"}
          id={"text-searchbox"}
          variant={"outlined"}
          value={textFilter}
          onChange={(e) => setTextFilter(e.target.value)}
          onKeyDown={handleKeyDown}
          InputProps={{
            startAdornment: (
              <InputAdornment position={"start"}>
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      {!matchMediaQuery ? (
        <>
          <Grid item xs={6} sm={6} md={2} lg={2}>
            <DateFilter
              label={"Last Day - From"}
              value={lastDayFromRequestDate}
              maxDate={lastDayToRequestDate}
              onChange={(newValue) => setLastDayFromRequestDate(newValue)}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={2} lg={2}>
            <DateFilter
              label={"Last Day - To"}
              value={lastDayToRequestDate}
              minDate={lastDayFromRequestDate}
              onChange={(newValue) => setLastDayToRequestDate(newValue)}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={2} lg={2}>
            <DateFilter
              label={"Request Date - From"}
              value={requestedFromDate}
              maxDate={requestedToDate}
              onChange={(newValue) => setRequestedFromDate(newValue)}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={2} lg={2}>
            <DateFilter
              label={"Request Date - To"}
              value={requestedToDate}
              minDate={requestedFromDate}
              onChange={(newValue) => setRequestedToDate(newValue)}
            />
          </Grid>
          {/* <Grid item xs={6} sm={6} md={2} lg={2}>
            <DateFilter
              label={"Washed Date"}
              value={washedDate}
              onChange={(newValue) => setWashedDate(newValue)}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={2} lg={2}>
            <DateFilter
              label={"Checked Date"}
              value={checkedDate}
              onChange={(newValue) => setCheckedDate(newValue)}
            />
          </Grid> */}
          <Grid item xs={6} sm={6} md={2} lg={2}>
            <Autocomplete
              id={"status-filter"}
              value={statusFilter}
              onChange={(e, newValue) => {
                setStatusFilter(newValue);
              }}
              options={statusList}
              getOptionLabel={(option) => option}
              renderInput={(params) => (
                <TextField {...params} label={"Status"} variant={"outlined"} />
              )}
              popupIcon={<KeyboardArrowDown />}
            />
          </Grid>
          {auth.checkModulePrivilege(
            PrivilegeModules.car_wash_request,
            PrivilegeActions.option_supervisor,
          ) && (
            <Grid item xs={6} sm={6} md={2} lg={2}>
              <Autocomplete
                id={"option-leader-filter"}
                options={optionLeaderList}
                getOptionLabel={(option) => `${option.username}-${option.name}`}
                value={optionLeaderFilter}
                onChange={(e, newValue) => setOptionLeaderFilter(newValue)}
                renderInput={(params) => (
                  <TextField {...params} label={"Team"} variant={"outlined"} />
                )}
                popupIcon={<KeyboardArrowDown />}
              />
            </Grid>
          )}
        </>
      ) : (
        <Grid item xs={2}>
          <IconButton
            onClick={() => toggleRightbar(layoutDispatch)}
            className={classes.drawerToggleBtn}
          >
            {layoutState.isRightbarOpened ? (
              <ArrowBack className={classes.toggleRightBarIcon} />
            ) : (
              <PlaylistPlay className={classes.toggleRightBarIcon} />
            )}
          </IconButton>
        </Grid>
      )}
    </>
  );

  const tableRender = (
    <Grid item xs={12} sm={12} md={12} lg={12}>
      <DataTable
        fixedHeader={true}
        persistTableHead={true}
        columns={columns}
        data={matrixList}
        sortServer
        onSort={sortByColumn}
        pagination
        paginationServer
        paginationDefaultPage={page}
        paginationPerPage={pageSize}
        paginationTotalRows={collectionSize}
        onChangeRowsPerPage={(count) => handleRowsPerPageChanged(count)}
        onChangePage={(page) => handlePageChanged(page)}
      />
    </Grid>
  );

  const downloadButtonPopover = (
    <Popover
      className="mega-menu"
      open={!!popoverButtonOpen}
      anchorEl={popoverButtonOpen}
      onClose={handleClosePopoverButton}
      disableScrollLock
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <List>
        <ListItem>
          <ListItemButton onClick={downloadCarWashRequestReport}>
            <ListItemText primary={`Car Wash Request Details`} />
          </ListItemButton>
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemButton onClick={downloadWeeklyStatusByTeam}>
            <ListItemText primary={`Weekly Status by Team`} />
          </ListItemButton>
        </ListItem>
      </List>
    </Popover>
  );
  // ************************************************
  return (
    <>
      {isLoading && (
        <Backdrop style={{ zIndex: 1 }} open={isLoading}>
          <CircularProgress color={"inherit"} />
        </Backdrop>
      )}
      {downloadButtonPopover}
      <Grid container spacing={{ xs: 2, md: 3 }}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          {titleRender}
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} className={"action"}>
          {actionRender}
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Grid className={"form"}>
            <Grid container spacing={{ xs: 2, md: 3 }}>
              {filterRender}
              {tableRender}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default withRouter(CarWashRequestList);
