import React from "react";
import { Button } from "@mui/material";
import { withRouter } from "react-router-dom";
import { PrivilegeModules,PrivilegeActions } from "../../../../data/privileges.enum";

const ButtonUpdateOrder = ({
  auth,
  selectedItemsArr,
  setSuccessMessage,
  orderCode,
  formSubmitCaller,
  currentOrder,
  validationWrapper
}) => {
  const handleSubmitForm = async (data) => {
    const orderData = {
      ...data,
      staffId: data?.staff?.username,
      staffName: data?.staff?.name,
      staffDepartment: data?.staff?.department,
      staffCustomerNo: data?.staff?.customerNo,
      remark: data.remark.trim().toUpperCase(),
      items: selectedItemsArr.map(item => {
        return { itemId: item._id, itemQty: item.requestedQty }
      }),
    };
    await formSubmitCaller(
      '/v1/material-order/' + orderCode,
      "PUT",
      orderData
    ).then(result => {
      if (!result?.data?.error) {
        setSuccessMessage(`Material request ${result.data.order.incrementId} has been updated.`);
      }
    })
  }

  const isAbleToShowButton = () => {


    if (!auth.checkModulePrivilege(PrivilegeModules.material_request, PrivilegeActions.edit)) {
      return false
    }    

    if (!currentOrder?.status || currentOrder?.status === "CLOSED") {
      return false
    }

    return true
  }

  return (
    <>
      {isAbleToShowButton() &&
        <Button className={"primary"} type={"button"} onClick={validationWrapper(handleSubmitForm)}>Save</Button>}
    </>
  );
}

export default withRouter(ButtonUpdateOrder);
