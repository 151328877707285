import { Link } from "react-router-dom";
import moment from "moment";
import { convertThisToCurrency } from "../../../helper/convert-this-to-currency";

const statusPoint = (status) => {
  switch (status) {
    case "PENDING":
      return 0;
    case "OPEN":
      return 1;
    case "APPROVED":
      return 2;
    case "CLOSED":
      return 3;
    default:
      return 0;
  }
};

export const ColumnConfig = (permission) => [
  {
    name: "No.",
    width: "90px",
    selector: (row) => row.incrementId,
    cell: (row, index, column, id) => {
      if (permission.allowedViewDetail) {
        return (
          <Link to={"/app/material-request/card/" + row.incrementId}>
            {row.incrementId}
          </Link>
        );
      } else {
        return <>{row.incrementId}</>;
      }
    },
    sortable: true,
    sortField: "no",
  },
  {
    name: "Document No.",
    width: "150px",
    selector: (row) => row?.pkrNo,
    cell: (row, index, column, id) => {
      return "PKR/WSHB-" + row?.pkrNo;
    },
    sortable: true,
    sortField: "pkrNo",
  },
  {
    name: "Total Qty.",
    width: "100px",
    right: true,
    selector: (row) => row.totalItems,
    sortable: true,
    sortField: "totalQty",
  },
  {
    name: "Total Amount",
    width: "120px",
    right: true,
    selector: (row) => row.totalPrice,
    cell: (row, index, column, id) => {
      return convertThisToCurrency(row.totalPrice.toFixed(2));
    },
    sortable: true,
    sortField: "totalAmount",
  },
  {
    name: "Team",
    width: "80px",
    selector: (row) => row?.username,
    sortable: true,
    sortField: "username",
  },
  {
    name: "Store",
    width: "80px",
    selector: (row) => row.storeCode,
    sortable: true,
    sortField: "store",
  },
  {
    name: "Company",
    width: "80px",
    selector: (row) => row?.company?.code,
    sortable: true,
    sortField: "company",
  },
  {
    name: "Request Date",
    width: "150px",
    wrap: true,
    selector: (row) => moment(row.createdAt).format("DD/MM/YYYY HH:mm:ss"),
    sortable: true,
    sortField: "requestDate",
  },
  {
    name: "Fulfiled Date",
    width: "150px",
    wrap: true,
    selector: (row) => {
      return row.status === "CLOSED"
        ? moment(row?.fulfillDate).format("DD/MM/YYYY HH:mm:ss")
        : "-";
    },
    sortable: true,
    sortField: "fulfillDate",
  },
  {
    name: "Status",
    width: "90px",
    sortFunction: (rowA, rowB) => {
      const pointA = statusPoint(rowA.status);
      const pointB = statusPoint(rowB.status);

      if (pointA > pointB) {
        return 1;
      }

      if (pointA < pointB) {
        return -1;
      }

      return 0;
    },
    selector: (row) => row.status,
    sortable: true,
    sortField: "status",
  },
  {
    name: "Debit No.",
    width: "120px",
    selector: (row) => row?.navOrderRef,
    sortable: true,
    sortField: "navOrderRef",
  },
  {
    name: "Staff Withdrawal",
    width: "120px",
    center: true,
    selector: (row) => (row?.isWithdrawal ? "YES" : "NO"),
    sortable: true,
    sortField: "isWithdrawal",
  },
  {
    name: "Staff",
    width: "90px",
    wrap: true,
    selector: (row) => row?.staffId,
    sortable: true,
    sortField: "staffId",
  },
  // {
  //   name: "Staff Name",
  //   width: "90px",
  //   wrap: true,
  //   selector: row => row?.staffName,
  //   sortable: true,
  //   sortField: 'staffName'
  // },
  // {
  //   name: "Staff Department",
  //   width: "120px",
  //   wrap: true,
  //   selector: row => row?.staffDepartment,
  //   sortable: true,
  //   sortField: 'staffDepartment'
  // },
  {
    name: "Customer No.",
    width: "120px",
    wrap: true,
    selector: (row) => row.customerNo,
    sortable: true,
    sortField: "customerNo",
  },
  {
    name: "Ship-to Customer Name",
    width: "300px",
    wrap: true,
    selector: (row) => row.shipToCustomerName,
    sortable: true,
    sortField: "shipToCustomerName",
  },
  {
    name: "Bill-to Customer Name",
    width: "300px",
    wrap: true,
    selector: (row) => row.billToCustomerName,
    sortable: true,
    sortField: "billToCustomerName",
  },
  {
    name: "Project Manager",
    width: "250px",
    wrap: true,
    selector: (row) =>
      row?.projectManager
        ? `${row?.projectManager.userName}-${row?.projectManager.userId}`
        : "",
    sortable: true,
    sortField: "projectManager",
  },
  {
    name: "Project Name",
    width: "300px",
    wrap: true,
    selector: (row) =>
      row?.project
        ? `${row?.project.projectName}-${row?.project.projectId}`
        : "",
    sortable: true,
    sortField: "projectName",
  },
];
